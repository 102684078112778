/**
 * @File Script to redirect users to an age check page, except when they are already on the age check page.
 */

import constants from '../modules/constants';

/**
 * Initialize a route guard which will redirect users until they vrify their age.
 */
const init = () => { 
  const currentPath = window.location.pathname.replace(/\/$/, '');
  const currentlyOnAgeCheckPage = currentPath === constants.AGE_CHECK_PATH;
  const isAdult = localStorage.getItem(constants.AGE_CHECK_VERIFICATION_STORAGE_KEY);

  // Redirect if not on age check page and the isAdult variable is not stored in local storage
  if (!currentlyOnAgeCheckPage && !isAdult) {
    localStorage.setItem(constants.AGE_CHECK_REDIRECT_STORAGE_KEY, currentPath); // Save current path to redirect to after age check 
    window.location.href = constants.AGE_CHECK_PATH; // Navigate to the age check page
  }
}

export default init;
